import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer_background from "../../images/v2/careers/Footer_background.svg";
import HeaderTypography from "../../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../../components/v2/Typography/ParagraphTypography";
import Box from "@material-ui/core/Box";
import WhiteButton from "./WhightButton";

const useStyles = makeStyles(() => ({
	JoinUsRoot: {
		background: `url(${Footer_background})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
}));

function JoinUs() {
	const classes = useStyles();

	return (
		<Box
			alignItems="center"
			className={classes.JoinUsRoot}
			display="flex !important"
			flexDirection="column"
			px={2}
			py={8}
			textAlign="center"
		>
			<HeaderTypography color="common.white" mt={0} my={2} textAlign="center">
				Join Us!
			</HeaderTypography>
			<ParagraphTypography color="common.white" maxWidth={900} mt={0} textAlign="center">
				What we have achieved so far is the result of empowering ambitious, thoughtful people to do exceptional things.
				We’re betting the future of Pepper Cloud on that strategy. Embark on a career at Pepper Cloud - from anywhere.
			</ParagraphTypography>
			<WhiteButton
				className={classes.btn}
				color="primary"
				href={"https://angel.co/company/pepper-cloud-pte-1/jobs"}
				rel="nofollow"
				size="large"
				target="_blank"
				variant="contained"
			>
				Apply Now
			</WhiteButton>
		</Box>
	);
}

export default JoinUs;
