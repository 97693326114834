import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import sliderBG from "../images/v2/careers/Front_banner.svg";
import IdeaAndInnovation from "../images/v2/careers/Idea-and-Innovation.svg";
import BoundariesAndBeyond from "../images/v2/careers/Boundaries and Beyond.svg";
import DiversionAndInclusion from "../images/v2/careers/Diversion and Inclusion.svg";
import FreeAndFun from "../images/v2/careers/Free and Fun.svg";
import ProfessionalAndPersonal from "../images/v2/careers/Professional and Personal.svg";
import SingleAndSquad from "../images/v2/careers/Single and Squad.svg";
import Footer_background from "../images/v2/careers/Footer_background.svg";
import CareersImg from "../images/v2/careers/Careers-2-bg.svg";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import FeaturedOpening from "../components/v2/Careers/FeaturedOpening";
import TakeOwnership from "../images/v2/careers/Take Ownership.svg";
import BeProactive from "../images/v2/careers/Be Proactive.svg";
import BeTransparent from "../images/v2/careers/Be Transparent.svg";
import BePatient from "../images/v2/careers/Be patient.svg";
import BeEmpathetic from "../images/v2/careers/Be Empathetic.svg";
import StayCommitted from "../images/v2/careers/Stay Committed.svg";
import OurOffice from "../components/v2/OurOffice";
import Artboard from "../images/v2/careers/Artboard.svg";
import Beginyourjourney from "../images/v2/careers/Begin your journey.webp";
import WhiteButton from "../components/v2/WhightButton";
import JoinUs from "../components/v2/JoinUs";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";

const useStyles = makeStyles((theme) => ({
  sliderRoot: {
    background: `url(${sliderBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: 646,
    [theme.breakpoints.down("sm")]: {
      minHeight: 435,
    },
  },
  applyRoot: {
    background: `url(${CareersImg})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    // background: `url(${CareersImg})`,
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    // minHeight: 646,
  },
  BeginUsJourneyRoot: {
    background: `url(${sliderBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: 646,
  },
  JoinUsRoot: {
    background: `url(${Footer_background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  integrationRoot: {
    backgroundColor: "#f2f9f9",
    marginTop: theme.spacing(8),
    padding: "4rem 0",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 0,
    },
  },
  ourPrinciples: {
    border: "1px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    padding: theme.spacing(4),
    minHeight: "320px",
  },
  applyBtn: {
    width: 336,
    height: 73,
    fontWeight: 600,
    fontSize: 20,
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  whyChoose: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      height: "unset",
      paddingLeft: "unset",
    },
  },
  beginJourney: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  careerPara: {
    fontSize: "18px",
    maxWidth: "720px",
    color: "rgb(46, 63, 79)",
    fontWeight: "400",
    margin: "18px 0",
  },
}));

function careers() {
  const width = useWidth();
  const classes = useStyles();
  const [open, setOpen] = useState(0);

  const LifeAtPepperCloud = [
    {
      title: "Boundaries and Beyond",
      description:
        "Be your own boss and push your boundaries. Feel the independence and be driven by your goals.",
      logo: BoundariesAndBeyond,
      alt: "Icon to indicate life at Pepper Cloud CRM",
    },
    {
      title: "Professional and Personal",
      description:
        "Strike the right balance between your professional and personal life with flexible working hours and work from home policy.",
      logo: ProfessionalAndPersonal,
      alt: "Icon to indicate the importance of work-life balance and the right time in CRM career",
    },
    {
      title: "Diversion and Inclusion",
      description:
        "Be a part of a workplace that is inclusive of gender equality and cultural diversity.",
      logo: DiversionAndInclusion,
      alt: "Hourglass icon",
    },
    {
      title: "Single and Squad",
      description:
        "Be recognised, appreciated, and encouraged for your individual efforts and for your team efforts.",
      logo: SingleAndSquad,
      alt: "CRM professional icon",
    },
    {
      title: "Free and Fun",
      description:
        "Experience the joy of all sorts of fun - from casual banter to playful outings, to career growth opportunities.",
      logo: FreeAndFun,
      alt: "Icon to indicate a CRM professional having fun",
    },
    {
      title: "Idea and Innovation",
      description:
        "Be a unique clouder among other young, lively, and innovative clouders.",
      logo: IdeaAndInnovation,
      alt: "Bulb icon to indicate innovation in CRM",
    },
  ];
  const OurPrinciples = [
    {
      title: "Take Ownership",
      description:
        "Take ownership of your work and emotions. Ownership brings a sense of independence, attachment, and confidence.",
      logo: TakeOwnership,
      alt: "Icon to indicate taking ownership in their CRM career",
    },
    {
      title: "Be Proactive",
      description:
        "Be proactive in handling your roles and responsibilities. Productiveness leads to innovation and great ideas.",
      logo: BeProactive,
      alt: "A glowing bulb icon inidate proactiveness to grow in CRM career path",
    },
    {
      title: "Be Transparent",
      description:
        "Be communicative about your ideas and transparent in your work. Transparency leads to trust, a collaborative work environment, and great relationships.",
      logo: BeTransparent,
      alt: "A shield icon to indicate being communicative",
    },
    {
      title: "Be Patient",
      description:
        "Be patient while thinking, analyzing, strategizing, and delivering. Patience can lead to the creation of unique products.",
      logo: BePatient,
      alt: "Hourglass icon indicating the need of patience to grow in CRM career.",
    },
    {
      title: "Be Empathetic",
      description:
        "Be empathetic towards your acquaintances. Empathy brings harmony in the workplace, with unexplored potential to make and feel better.",
      logo: BeEmpathetic,
      alt: "Connected minds to indicate being empathetic",
    },
    {
      title: "Stay Committed",
      description:
        "Be committed to the chosen path. Commitment, with a certain extent of flexibility and remodelling, boosts productivity and efficiency.",
      logo: StayCommitted,
      alt: "Hand shake icon to indicate the commitment to CRM career path",
    },
  ];

  const jobDetails = [
    {
      position: "Technical Lead",
      location: "Engineering Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/1749534-technical-lead",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an experienced and passionate technical lead to
            design, develop and enhance our core product offerings.
            <ul>
              <li>
                As a senior member of the technology team, you will play a
                critical role in leading the design architecture, product
                development and technical strategy.
              </li>
              <li>
                You will take significant ownership of our core platform and
                architect it for speed and scalability. In short, you will be
                responsible for translating ideas, concept images and business
                requirements to an enterprise grade product.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                3+ years of hands-on experience in designing, developing and
                managing products
              </li>
              <li>
                4+ years of experience in shipping reliable, scalable, and
                efficient code, preferably in a full-stack role
              </li>
              <li>
                Solid experience in technical architecture and implementation of
                scalable web applications
              </li>
              <li>
                Deep understanding of the JavaScript language and hands-on
                experience with modern JavaScript UI libraries, frameworks and
                design
              </li>
              <li>patterns (React, Angular, Node, Redux, Saga)</li>
              <li>
                Proven expertise in growing a product centric team and culture
              </li>
              <li>
                Excellent people management, analytical and communication skills
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                Experience in designing and developing enterprise grade SaaS
                applications
              </li>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <li>Start-up experience</li>
              <li>"Get things done" attitude.</li>
              <li>Experience working with remote teams</li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Key product management role in a strong and growing team</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Full Stack Engineer",
      location: "Engineering Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/1743375-full-stack-engineer",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for a passionate
            <ul>
              <li>
                Software Engineer to design, develop, and scale our core product
                offerings. As a core member of the technology team, you will
                play a critical role in creating responsive frontend interfaces,
                building backend platform services and streamlining the build
                infrastructure.
              </li>
              <li>
                Right from the start, you will take significant ownership of
                parts of the platform and continuously involved in product
                planning and strategy. In short, you will wear many hats, touch
                many parts of our system, and play an instrumental role in
                shaping our product suite.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                Minimum of 3 years of software engineering experience,
                preferably in an isomorphic/universal JavaScript environment
              </li>
              <li>
                Practical experience with modern JavaScript libraries and
                frameworks (including but not limited to React, Angular,
                Express, Sails, Redux)
              </li>
              <li>
                Solid understanding of the JavaScript language (ECMAScript
                2015+), HTML5 and CSS
              </li>
              <li>Strong knowledge of RESTful API design and implementation</li>
              <li>
                Experience with unit testing, continuous integration and
                deployment preferred
              </li>
              <li>
                Basic understanding of Javascript build and automation tools
                (e.g., Webpack, Grunt, etc.)
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Start-up experience</li>
              <li>Mobile development experience a solid plus</li>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <li>Experience with microservices-based architecture a plus</li>
              <li>"Get things done" attitude.</li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Key role in a small but strong and growing team</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Frontend Developer",
      location: "Engineering Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/1743380-frontend-developer",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an experienced and passionate frontend developer
            to design and develop our core product offerings. You will be a core
            member of the engineering team who can:
            <ul>
              <li>
                As a core member of the technology team, you will play a
                critical role in creating responsive user interfaces and
                implementing functional UI from concept images and wireframes.
              </li>
              <li>
                You will take significant ownership of the frontend platform and
                architect it for speed and scalability.
              </li>
              <li>
                Working closely with design and backend team, you will help
                establish a shared UI vision for our product suite.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                Minimum of 3 years of frontend software engineering experience,
                preferably in a JavaScript environment
              </li>
              <li>
                Practical experience with modern JavaScript UI libraries and
                frameworks (e.g., React, Angular, Vue)
              </li>
              <li>
                Deep understanding of the JavaScript language (ECMAScript
                2015+), HTML5 and CSS
              </li>
              <li>
                Knowledge of front-end architecture especially in designing
                large scale single-page applications
              </li>
              <li>
                Understanding of fundamental design skills (e.g., color,
                typography, layout, etc.) and passion for developing intuitive
                and responsive interfaces
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Start-up experience</li>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <li>
                Experience with visualization libraries like D3 a solid plus
              </li>
              <li>"Get things done" attitude</li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Key role in a small but strong and growing team</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Business Analyst",
      location: "Engineering Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/2138021-business-analyst",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an experienced
            <ul>
              <li>
                Business Analyst to manage the functional and non-functional
                requirement of our various products and their features.
              </li>
              <li>
                The person should have strong observational, and analytical
                skills to breakdown the business and product requirement in
                ‘easy to comprehend’ user stories.
              </li>
              <li>
                The person would act as a strong bridge between the business and
                technical team and would be responsible for driving new product
                features.
              </li>
            </ul>
            <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
              Key Job Responsibilities:
            </Box>
            <ul>
              <li>
                Gathering business and functional requirements and document them
                in clear and concise format, which is focused on the reader and
                is without unnecessary detail.
              </li>
              <li>
                Translating these business needs in to BRD, use cases/ user
                stories, process flows, data flows, UI specifications and wire
                frames and other supporting requirement documentation to be
                utilized in an agile development environment.
              </li>
              <li>
                Proficient with tools like Jira, Confluence, Balsamiq, Axure,
                Mockplus, Draw.io, Visio, etc.
              </li>
              <li>
                Work closely with the business and technology team to gather &
                write end to end business testing requirements and acceptance
                criteria.
              </li>
              <li>
                Demonstrate new functionality to business and technology users.
                Interact with the business users and convey the information to
                the developers
              </li>
              <li>
                Review and approve functional test scenarios and perform testing
                as necessary for team to meet iteration goals.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                2-4 yrs. of hands on experience in software applications as a
                Business Analyst. Well versed with IT technologies
              </li>
              <li>Masters or Bachelor Degree</li>
              <li>
                Strong analytical skills; Attentive listener; Ability to solve
                complex problems.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Exceptional written and verbal communication skills.</li>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <li>
                Ability to multitask and work on a diverse range of
                requirements.
              </li>
              <li>Strong teamwork and interpersonal skills</li>
              <li>Ability to read and interpret technical specifications.</li>
              <li>
                Ability to comprehend audience background and expectations.
              </li>
              <li>Ability to correctly prioritize work and meet deadlines.</li>
              <li>
                Self - motivated, flexible, and comfortable with a fast - paced
                startup
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Flexible work hours and holiday policy</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Junior UI UX Designer",
      location: "Design Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/1311990-senior-ui-ux-designer",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an experienced and passionate designer to join
            our growing product and design team who can:
            <ul>
              <li>
                Design intuitive interfaces, flows, and experiences for web,
                mobile, and other form factors
              </li>
              <li>
                Translate broad, ambiguous ideas and business requirements into
                delightful and conversion-oriented user journeys, with a high
                degree of autonomy
              </li>
              <li>
                Lead the design process all the way from discovery and analysis,
                creating user flows, wireframes and storyboards to building user
                interface mockups and prototypes
              </li>
              <li>
                Work in an interdisciplinary environment involving designers,
                user researchers, product managers, and engineers to brainstorm
                and shape product design
              </li>
              <li>Build an updated visual language for our product suite.</li>
              <li>
                Stay up-to-date and educate internal members on evolving design
                trends and best practices in the B2B/B2C space.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                2+ years of hands-on experience in designing and shipping
                products across web and mobile.
              </li>
              <li>
                2+ years of experience in interaction and visual design,
                preferably in a B2B/B2C domain
              </li>
              <li>
                Fluency with responsive web design principles and guidelines.
              </li>
              <li>
                Proficient knowledge of color theory, grid layout, typography,
                and graphic design.
              </li>
              <li>
                Proficiency with design tools Sketch, Figma, Adobe XD,
                Illustrator (we are tool-agonistic).
              </li>
              <li>
                Solid communication skills, with the ability to clearly
                articulate your design decisions.
              </li>
              <li>
                BA/BS in design-related domain, or equivalent industrial
                experience.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Start-up experience</li>
              <li>Mobile development experience a solid plus</li>
              <li>Experience working with remote teams.</li>
              <li>
                Experience in designing and developing enterprise-grade
                applications is a solid plus.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Graphics Designer",
      location: "Design Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/2051108-graphics-designer",
      open: true,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an experienced and passionate Graphics designer
            to join our growing product and design team who has:
            <ul>
              <li>Minimum 2yrs of work experience.</li>
              <li>
                Advertising Agency Experience, Graphic Design Skills, Layout
                Skills, Creative Ideation, Customer Focus, Creativity,
                Flexibility, Attention to Detail, Deadline-Oriented, Acute
                Vision, Illustration, Sketching.
              </li>
              <li>
                Skilled with Photoshop, Corel DRAW, Illustrator, InDesign, and
                other Adobe Creative Cloud Tools.
              </li>
              <li>
                Create digital assets for the brand – newsletters, brochure,
                EDMs, landing pages etc.
              </li>
              <li>
                Ideating, Conceptualizing and Designing original pieces,
                including social media posts, animation, illustrations and
                infographics.
              </li>
              <li>Work on a consistent theme and layout.</li>
              <li>
                Should be creative with colors, fonts, layouts, icons,
                illustrations.
              </li>
              <li>
                Provide ideas and concepts and developing an interactive design
                for the same with an understanding of all the latest trends.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Start-up experience and working with remote teams.</li>
              <li>Degree/Diploma in Arts/Designing.</li>
              <li>
                Experience in designing and developing enterprise-grade
                applications is a solid plus.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Inside Sales Executive",
      location: "Marketing Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/1767448-inside-sales-executive",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an Inside Sales Manager preferably someone from
            technology sales background. You will be responsible for:
            <ul>
              <li>Selling to SE Asia Markets.</li>
              <li>
                Generate new business by prospecting, cold calling, leveraging
                networks, etc
              </li>
              <li>
                Understanding of tools such as LinkedIn, Mailchimp, and CRM to
                establish and build relationships in order to generate and
                qualify leads.
              </li>
              <li>A proven track record in a fast-paced sales environment</li>
              <li>
                Quota - Meeting and exceeding monthly, quarterly, and annual
                quotas
              </li>
              <li>
                Having the ability to share/receive constructive feedback on the
                quality of leads, expectations, and overall pipeline health
              </li>
              <li>
                Using internal CRM, complete and report 50-70 daily outbound
                activities
              </li>
              <li>
                Incentive driven. Willing to adopt a performance-based sales
                model by tracking critical sales metrics and keeping CRM updated
                daily
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Experience in SAAS selling to SMB/SME.</li>
              <li>
                2-3 years inside sales experience, preferably in technology
                sales
              </li>
              <li>Excellent command over English.</li>
              <li>
                Strong verbal and written communication skills with the ability
                to deliver powerful presentations and product demos.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Start-up experience</li>
              <li>"Get things done" attitude</li>
              <li>Experience working with remote teams.</li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Fixed annual bonus</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "QA Engineer",
      location: "Engineering Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/2012537-qa-engineer",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for a passionate full stack QA engineer to design and
            execute manual and automated testing for our core product offerings.
            You will be a core member of the engineering team who can:
            <ul>
              <li>
                Create detailed, comprehensive and well-structured test plans
                and test cases as per business requirements and technical
                specifications
              </li>
              <li>
                Lead end-to-end manual (and preferably automation) testing for
                both frontend (browser) and backend (API) applications
              </li>
              <li>
                Perform thorough regression testing for functional and
                non-functional product features
              </li>
              <li>
                Collaborate with full stack engineers and product managers to
                identify and understand feature requirements
              </li>
              <li>
                Gathering business and functional requirements and document them
                in clear and concise format, which is focused on the reader and
                is without unnecessary detail
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                Minimum of 4 years of quality assurance and test engineering
                experience
              </li>
              <li>
                Proven experience in writing clear, concise and comprehensive
                test plans and test cases
              </li>
              <li>Experience in testing enterprise-grade SaaS applications</li>
              <li>Strong analytical and problem-solving skills</li>
              <li>
                Basic understanding of JavaScript based test automation tools
                and frameworks (Cypress, Mocha) preferred
              </li>
              <li>
                Team player with an ability to work efficiently with a high
                degree of autonomy
              </li>
              <li>Familiarity with tools such as Postman, Jira, GIT</li>
              <li>
                Bachelor’s degree in Computer Science, Computer Engineering,
                Electrical Engineering or equivalent.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Desirable Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Start-up experience</li>
              <li>"Get things done" attitude</li>
              <li>Experience working with remote teams</li>
              <li>
                Experience with performance and/or security testing is a solid
                plus
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Fixed annual bonus</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Copywriter",
      location: "Marketing Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/2267340-copywriter",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for a passionate copywriter who can write clear and
            concise copy for our digital marketing activities. The roles &
            responsibilities will be to:
            <ul>
              <li>Write compelling copy across all marketing channels.</li>
              <li>Edit & proofread all copy before it goes live</li>
              <li>
                Knows how to use digital marketing tools, search engine
                optimization.
              </li>
              <li>
                Collaborate with graphic designers & other team members to
                create visually attractive content
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Basic Qualifications:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                Bachelors’ degree in English/journalism or another related major
              </li>
              <li>Outstanding writing skills</li>
              <li>Experience with proofreading</li>
              <li>Ability to work independently & with a team</li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
    {
      position: "Digital Marketing Manager",
      location: "Marketing Team, Bengaluru",
      applyLink:
        "https://angel.co/company/pepper-cloud-pte-1/jobs/1872205-digital-marketing-manager",
      open: false,
      content: (
        <React.Fragment>
          <Box color="#2e3f4f" fontSize="16px">
            We are looking for an experienced and passionate Digital Marketing
            Manager, who can lead our efforts in digital marketing to increase
            our brand visibility & lead generation.
            <ul>
              <li>
                The ideal candidate will expand the company's brand presence by
                finding the target audience to distribute marketing content to.
              </li>
              <li>
                You will create and implement the marketing strategy using both
                offline and online methods to gain new customers.
              </li>
              <li>
                The ideal candidate is both a self-motivated individual and a
                positive team player.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Job Responsibilities
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                Optimize website content for maximum visibility and ranking on
                major search engines.Drive external traffic to generate
                conversions for new leads and also for branding purposes.
              </li>
              <li>
                Execute all digital marketing campaigns according to the
                Marketing roadmap, including SEO/SEM, paid social media and
                display advertising campaigns. From campaign set up to
                optimization.
              </li>
              <li>
                Structure and style emails, make tweaks to landing pages,
                customize forms, format blog posts, and more.
              </li>
              <li>
                Develop an overall Social Media Marketing Plan and Strategy and
                work with copy writers & designers to develop campaign pages,
                banners and online promotional material.
              </li>
              <li>
                Must be able to support setting up new automated email
                campaigns. Experience in working with Mailchimp, Active Campaign
                etc.
              </li>
              <li>
                Measure and report performance of all digital marketing
                campaigns, and assess against goals (ROI and KPIs). Analyze and
                optimize user funnels, tracking, audience targeting.
              </li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Required Skills:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>
                3+ year’s full-time experience in digital marketing (B2B
                marketing in SaaS background preferred).
              </li>
              <li>Email campaigns management experience is a must.</li>
              <li>
                Good knowledge of on page and off page SEO
                strategies/techniques. Broader experience in Paid, Owned and
                Earned media (search, programmatic, performance marketing,
                analytics, site builds and content, social media, influencer
                engagement etc)
              </li>
              <li>Good knowledge of SEM and PPC campaigns.</li>
              <li>
                Strong knowledge of search engine algorithms and ranking
                strategies.
              </li>
              <li>
                Ability to identify and isolate trending keywords for a given
                topic and/ or different geographical locations.
              </li>
              <li>
                Ability to work with social media channels like Facebook,
                Twitter, LinkedIn, Blogs, etc
              </li>
              <li>Excellent communication skills, both verbal and written.</li>
            </ul>
          </Box>
          <Box color="#2e3f4f" fontSize="18px" mb="10px" mt="10px">
            Benefits:
          </Box>
          <Box color="#2e3f4f" fontSize="16px">
            <ul>
              <li>Competitive salary</li>
              <li>Flexible work hours and holiday policy</li>
              <li>Open and collaborative work culture</li>
            </ul>
          </Box>
        </React.Fragment>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <SEO
        canonical="/careers"
        description="Build your career with Singapore's #1 sales CRM software. Progress in your career path and succeed with us while redefining the customer relationships. Check out open job positions. Apply now!"
        keywords="Career,CRM Career path, CRM Career,jobs in singapore,CRM related jobs "
        pathname="/careers"
        title="Careers and Jobs | Pepper Cloud CRM"
      />
      <Container>
        <Grid alignItems="center" container justify="center" spacing={4}>
          <Grid className={classes.header} item md={6} sm={12}>
            <HeaderTypography component="h2" mb={4} mt={2}>
              {/*Career and Beyond /*/}
              Pursuit of a successful career is within reach
            </HeaderTypography>

            <ParagraphTypography mb={6}>
              A career at Pepper Cloud is more than just a job. It is an
              opportunity to shape your future by doing what you love.
            </ParagraphTypography>
            <Button
              color="secondary"
              href={"https://angel.co/company/pepper-cloud-pte-1/jobs"}
              rel="nofollow"
              size="large"
              target="_blank"
              variant="contained"
            >
              Join the Team
            </Button>
          </Grid>
          <Grid className={classes.sliderRoot} item md={6} sm={12} xs={12} />
        </Grid>
      </Container>
      <div className={classes.integrationRoot}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          py={1}
          textAlign="center"
        >
          <Grid alignItems="center" container justify="center">
            <Grid item md={6} sm={12}>
              <Box
                alt="Pic of a professional"
                component="img"
                height="auto"
                mb={2}
                mt={2}
                src={Artboard}
                width="100%"
              />
            </Grid>
            <Box
              className={classes.whyChoose}
              component={Grid}
              display="flex"
              flexDirection="column"
              height={600}
              item
              justify="center"
              md={6}
              pl={8}
              sm={12}
            >
              <HeaderTypography component="h1" m={0} textAlign="left">
                Thrive and grow with Singapore’s #1 Sales CRM
              </HeaderTypography>
              <ParagraphTypography mb={0} mt={1} textAlign="left">
                We are more than just a workplace. We are a family!
                <br />
                <br />
                At Pepper Cloud, we build a friendly culture where amazing
                people like you, can tap into their greatest strengths and
                perform at their best. We are fixated on harnessing the
                technologies to cultivate your career, nurture customer
                relationships, grow our clients, and disrupt the world to create
                a better one. Join us to discover a future of possibilities,
                help millions of businesses, and have a successful CRM career
                path.
              </ParagraphTypography>
            </Box>
          </Grid>
        </Box>
      </div>
      <Box
        alignItems="center"
        component={Container}
        display="flex !important"
        flexDirection="column"
        mb={6}
        py={{ xl: 3, md: 3 }}
        textAlign="center"
      >
        <HeaderTypography component="h2" my={3}>
          Life at Pepper Cloud
        </HeaderTypography>

        <Grid container spacing={4}>
          {LifeAtPepperCloud.map((each) => (
            <Grid item key={each.title} md={4} sm={6} xs={12}>
              <div>
                <Box
                  alt={each.alt}
                  component="img"
                  mb={2}
                  mt={3}
                  src={each.logo}
                  width={70}
                />
                <HeaderTypography
                  component="h3"
                  fontSize={24}
                  fontWeight={600}
                  m={0}
                >
                  {each.title}
                </HeaderTypography>
                <ParagraphTypography
                  color="text.paragraph"
                  fontSize={16}
                  mt={2}
                >
                  {each.description}
                </ParagraphTypography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <div className={classes.applyRoot}>
        <Container maxWidth="lg">
          <Grid alignItems="center" container>
            <Grid item md={5} xs={12}>
              <HeaderTypography
                color="common.white"
                component="h3"
                fontSize={36}
                mb={4}
                mt={2}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Begin your journey with us
              </HeaderTypography>
              <ParagraphTypography
                color="common.white"
                mb={6}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Work alongside brilliant and inspiring people to reshape the
                landscape and make amazing products. Be more than just your job
                title, and join us to solve some interesting problems.
              </ParagraphTypography>
              <Box textAlign={{ sm: "center", xs: "center" }[width] || "left"}>
                <WhiteButton
                  color="primary"
                  href={"https://angel.co/company/pepper-cloud-pte-1/jobs"}
                  rel="nofollow"
                  size="large"
                  target="_blank"
                  variant="contained"
                >
                  Apply Now
                </WhiteButton>
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Box
                alt="Ratings from various websites such as Naukri, Google, Glassdoor, and LinkedIn"
                component="img"
                height="auto"
                maxWidth="100%"
                mb={{ sm: 6, xs: 6, md: 17 }}
                mt={2}
                src={Beginyourjourney}
                width="100%"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Box
        alignItems="center"
        component={Container}
        display="flex !important"
        flexDirection="column"
        mb={3}
        py={3}
        textAlign="center"
      >
        <HeaderTypography component="h3" my={3}>
          Our Principles
        </HeaderTypography>

        <ParagraphTypography maxWidth={720} mb={{ md: 8 }}>
          We strongly believe that true success is an outcome of our work
          principles. Our principles serve as the foundation of our belief and
          behaviour.
        </ParagraphTypography>
        <Grid container spacing={4}>
          {OurPrinciples.map((each) => (
            <Grid item key={each.title} md={4} sm={6} xs={12}>
              <div className={classes.ourPrinciples}>
                <Box
                  alt={each.alt}
                  component="img"
                  my={2}
                  src={each.logo}
                  width={70}
                />
                <Typography component="h4" variant="h5">
                  {each.title}
                </Typography>
                <ParagraphTypography
                  color="text.paragraph"
                  fontSize={16}
                  mb={0}
                  mt={3}
                >
                  {each.description}
                </ParagraphTypography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        alignItems="center"
        component={Container}
        display="flex !important"
        flexDirection="column"
        mb={3}
        py={3}
        textAlign="center"
      >
        <HeaderTypography component="h2" my={3}>
          Pepper Cloud – Simple CRM for Smart People
        </HeaderTypography>

        <h2 className={classes.careerPara}>
          We believe that every business needs the right tools to drive its
          growth. We are on a mission to assist in their growth by providing
          them with a powerful sales CRM.
        </h2>
        <ParagraphTypography maxWidth={720} mb={{ md: 12 }}>
          With our user-friendly CRM tool, businesses can experience unique
          business solutions, build new customer relationships and foster
          existing relationships. Our coherent and sensible product helps the
          users to create, customise, and manage their sales process and the way
          they nourish customer relationships.
          <br />
          <br />
          Come and join{" "}
          <a href="https://peppercloud.com/"> Singapore’s #1 sales CRM</a> team,
          work and build your career with us while redefining the way customer
          relationships are managed.
        </ParagraphTypography>
      </Box>
      <Box bgcolor="#f2f9f9" pb={{ md: 10 }}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          py={3}
          textAlign="center"
        >
          <HeaderTypography component="h2" mb={0} my={{ xl: "3", md: "3" }}>
            Featured jobs
          </HeaderTypography>
        </Box>
        <Box component={Container} mb={3} mt={{ md: 6 }}>
          {jobDetails.map((each, index) => (
            <FeaturedOpening
              each={each}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              open={index === open}
              setChange={() => setOpen(index === open ? -1 : index)}
            />
          ))}
        </Box>
      </Box>
      <Box
        alignItems="center"
        component={Container}
        display="flex !important"
        flexDirection="column"
        mb={{ xl: "6", md: "6" }}
        py={{ xl: "3", md: "3" }}
        textAlign="center"
      >
        <HeaderTypography component="h2" my={3}>
          Pepper Cloud offices around the world
        </HeaderTypography>

        <ParagraphTypography maxWidth={720} mb={{ md: 7 }}>
          We have a presence across Asia and are intelligently growing our CRM
          software beyond boundaries.
        </ParagraphTypography>
        <OurOffice showHeading={false} />
      </Box>
      <JoinUs />
    </div>
  );
}

export default careers;
