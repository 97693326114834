import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	root: {
		// width: "100%",
		boxShadow: "0 3px 20px 0 rgb(0 0 0 / 10%)",
	},
	heading: {
		fontSize: 22,
		fontWeight: 600,
		[theme.breakpoints.down("sm")]: {
			fontSize: 20,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
		},
	},
	secondaryHeading: {
		fontSize: 16,
		color: "#516f90",
		[theme.breakpoints.down("sm")]: {
			fontSize: 16,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
		},
	},
	icon: {
		verticalAlign: "bottom",
		height: 20,
		width: 20,
	},
	btn: {
		minWidth: "unset",
		width: 157.8,
		height: 55.5,
		padding: "16px 28.8px 16.5px 29px",
		borderRadius: 5,
		border: "solid 1px #ff7a59",
		fontSize: 16,
		fontWeight: 600,
		"&:hover": {
			color: "#ff7a59",
		},
		[theme.breakpoints.down("sm")]: {
			width: 118.8,
			height: 43,
			// padding: "unset",
			fontSize: 11,
		},
	},
}));

function FeaturedOpening({ open, each, setChange }) {
	const { position, location, applyLink, content } = each;

	const classes = useStyles();

	return (
		<Box mb={3}>
			<Accordion className={classes.root} expanded={open} onChange={setChange}>
				<AccordionSummary>
					<Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
						<div className={classes.column}>
							<Typography className={classes.heading} variant="h2">
								{position}
							</Typography>
							<Typography className={classes.secondaryHeading}>{location}</Typography>
						</div>
						<Box>
							{open ? (
								<Button
									className={classes.btn}
									color="secondary"
									href={applyLink}
									rel="nofollow"
									size="large"
									target="_blank"
									variant="outlined"
								>
									Apply
								</Button>
							) : (
									<Button className={classes.btn} color="secondary" size="large" variant="outlined">
										See Position
								</Button>
								)}
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails className={classes.details}>
					<div className={classes.column} />
					<div className={classes.column}>{content}</div>
				</AccordionDetails>
				<Divider />
			</Accordion>
		</Box>
	);
}

FeaturedOpening.propTypes = {
	open: PropTypes.bool,
	each: PropTypes.object,
	setChange: PropTypes.func,
};

FeaturedOpening.defaultProps = {
	open: false,
};

export default FeaturedOpening;
